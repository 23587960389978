import { Button } from '@streamr/streamr-layout'
import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import { Section, SectionDescription, SectionTitle } from '../../Section'
import { SkewedTile, SkewedTileList } from '../../SkewedTile'
import Operator from '../assets/operator.png'
import Operator2x from '../assets/operator@2x.png'

export function BecomeANodeOperator() {
    return (
        <BecomeANodeOperatorRoot>
            <NonDesktopView>
                <SectionTitle>Become a Node Operator</SectionTitle>
                {illustration}
                <Content>{content}</Content>
            </NonDesktopView>
            <DesktopView>
                <Wings>
                    <Content>
                        <SectionTitle>Become a Node Operator</SectionTitle>
                        {content}
                    </Content>
                    {illustration}
                </Wings>
            </DesktopView>
        </BecomeANodeOperatorRoot>
    )
}

const NonDesktopView = styled.div`
    @media ${MqDesktop} {
        display: none;
    }
`

const DesktopView = styled.div`
    display: none;

    @media ${MqDesktop} {
        display: block;
    }
`

const Tiles = styled(SkewedTileList)`
    --size: 140px;
    --bg: #ffffff;
    --imgDy: -24%;
    --imgWidth: 128px;

    top: 50%;

    @media ${MqTablet} {
        --size: 160px;
        --imgWidth: 148px;
    }

    @media ${MqDesktop} {
        --size: 224px;
        --imgWidth: auto;
    }
`

const Buttons = styled.div`
    margin-top: 24px;

    ${Button} {
        width: 100%;
    }

    @media ${MqDesktop} {
        margin: 0;

        ${Button} {
            width: auto;
        }
    }
`

const GuideCtaDesc = styled.div`
    font-size: 16px;
    line-height: 26px;

    @media ${MqTablet} {
        font-size: 18px;
    }

    @media ${MqDesktop} {
        font-size: 16px;
    }
`

const GuideCta = styled.div`
    box-shadow: 0px 6.30704px 50px -6.30704px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;

    @media ${MqTablet} {
        padding: 32px;
    }

    @media ${MqDesktop} {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
    }
`

const Illustration = styled.div`
    margin: 180px 0 140px;
    position: relative;

    @media ${MqTablet} {
        margin: 200px 0 180px;
    }

    @media ${MqDesktop} {
        margin: 0;
    }
`

const Content = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 32px;
    position: relative;
    text-align: center;
    width: 552px;
    z-index: 1;

    @media ${MqDesktop} {
        box-sizing: content-box;
        margin: 0;
        padding: 0;
        text-align: left;
        width: auto;
    }
`

const Wings = styled.div`
    display: grid;
    grid-template-columns: 552px auto;
    gap: 32px;
    width: 1024px;
    margin: 0 auto;
`

const BecomeANodeOperatorRoot = styled(Section)`
    background: #f5f5f5;
    color: #0c009a;
    background-image: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #f5f5f5 100%),
        linear-gradient(190.29deg, #e1d7ff 2.3%, #dbeaf6 36%, #f5f5f5 100%);
    overflow: hidden;

    ${SectionTitle} {
        position: relative;
        text-align: center;
        z-index: 1;
    }

    @media ${MqDesktop} {
        ${SectionTitle} {
            text-align: left;
        }
    }
`

const illustration = (
    <Illustration>
        <Tiles>
            <SkewedTile />
            <SkewedTile
                style={{
                    '--ty': '-128%',
                    opacity: 0.3,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '-216%',
                    '--ty': '-128%',
                    opacity: 0.15,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '-216%',
                    '--ty': '-256%',
                    opacity: 0.2,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '-216%',
                    opacity: 0.2,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '108%',
                    '--ty': '-64%',
                    opacity: 0.2,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '-108%',
                    '--ty': '-64%',
                    opacity: 0.25,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '108%',
                    '--ty': '64%',
                    opacity: 0.3,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '-108%',
                    '--ty': '64%',
                    opacity: 0.2,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '108%',
                    '--ty': '-192%',
                    opacity: 0.2,
                }}
            />

            <SkewedTile
                style={{
                    '--tx': '324%',
                    '--ty': '-192%',
                    opacity: 0.2,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '324%',
                    '--ty': '-64%',
                    opacity: 0.2,
                }}
            />
            <SkewedTile
                style={{
                    '--tx': '216%',
                    '--ty': '-128%',
                    opacity: 0.3,
                }}
            />
            <SkewedTile
                style={{
                    '--ty': '128%',
                    opacity: 0.15,
                }}
            />
            <img src={Operator} srcSet={`${Operator2x} 2x`} alt="Operator" />
        </Tiles>
    </Illustration>
)

const content = (
    <>
        <SectionDescription>
            Operators in the Streamr Network run nodes to relay data and earn DATA&nbsp;tokens.
        </SectionDescription>
        <SectionDescription>
            Operators manage a smart contract that joins and stakes on stream Sponsorships that
            assign their nodes to relay data for DATA&nbsp;tokens.
        </SectionDescription>
        <SectionDescription>
            If operating a node seems too complex, you have the option to hire a&nbsp;third-party
            service to manage a Streamr node for you. This is known as Nodes as&nbsp;a&nbsp;Service.
        </SectionDescription>
        <GuideCta>
            <GuideCtaDesc>
                Step-by-step instructions to become a&nbsp;Node&nbsp;Operator
            </GuideCtaDesc>
            <Buttons>
                <Button
                    tag="a"
                    href="https://docs.streamr.network/guides/become-an-operator"
                    kind="primary"
                    size="big"
                >
                    Getting started guide
                </Button>
            </Buttons>
        </GuideCta>
    </>
)
