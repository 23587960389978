import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import { opacityPulseKeyframes } from '../../../utils/css'
import { formatFloat, formatInt, useBasicNetworkStats } from '../../../utils/network'
import { BaseHero } from '../../BaseHero'
import { SkewedTile } from '../../SkewedTile'
import Apys from '../assets/apys.png'
import Apys2x from '../assets/apys@2x.png'
import Operators from '../assets/operators.png'
import Operators2x from '../assets/operators@2x.png'
import Reward from '../assets/reward.png'
import Reward2x from '../assets/reward@2x.png'
import Stakes from '../assets/stakes.png'
import Stakes2x from '../assets/stakes@2x.png'

export function StakeAndEarnHero() {
    const { operatorCount, totalStake, apy } = useBasicNetworkStats()

    return (
        <StakeAndEarnHeroRoot
            head={
                <>
                    <Background2 />
                    <Background1 />
                    <Background0 />
                </>
            }
            content={
                <>
                    <h1>
                        Stream data.
                        <br />
                        Earn <em>$DATA</em>.
                    </h1>
                    <h2>
                        Join the ranks of Streamr, where a global community powers
                        a&nbsp;decentralized network. Help secure the network by deploying
                        a&nbsp;Streamr node and earn through your idle&nbsp;bandwidth.
                    </h2>
                </>
            }
            icon={<img src={Reward} srcSet={`${Reward2x} 2x`} alt="Earn" />}
            tail={
                <Stats>
                    <Stat>
                        <StatDetails $loading={operatorCount == null}>
                            <h4>{formatInt(operatorCount || 0)}</h4>
                            <p>Active Operators</p>
                        </StatDetails>
                        <div>
                            <OperatorsImg
                                width="105"
                                height="123"
                                src={Operators}
                                srcSet={`${Operators2x} 2x`}
                                alt="Operators"
                            />
                        </div>
                    </Stat>
                    <Stat>
                        <StatDetails $loading={totalStake == null}>
                            <h4>{formatFloat((totalStake || 0) / 10 ** 6)}M</h4>
                            <p>$DATA staked on Operators</p>
                        </StatDetails>
                        <div>
                            <StakesImg
                                width="95"
                                height="111"
                                src={Stakes}
                                srcSet={`${Stakes2x} 2x`}
                                alt="Stake"
                            />
                        </div>
                    </Stat>
                    <Stat>
                        <StatDetails $loading={apy == null}>
                            <h4>{formatFloat(Number(apy || 0))}%</h4>
                            <p>Operator APY</p>
                        </StatDetails>
                        <div>
                            <ApysImg
                                width="153"
                                height="106"
                                src={Apys}
                                srcSet={`${Apys2x} 2x`}
                                alt="APY"
                            />
                        </div>
                    </Stat>
                </Stats>
            }
        />
    )
}

const Background0 = styled.div`
    position: absolute;
    height: calc(100% + 80px);
    width: calc(100% + 80px);
    top: 0px;
    left: 0px;
    background-image: radial-gradient(
        circle at var(--RingX, 50%) calc(100% + var(--RingOffsetY)),
        transparent 0px,
        transparent calc(var(--RingRadius) - var(--RingWeight) - 1px),
        #ffffff calc(var(--RingRadius) - var(--RingWeight)),
        #ffffff var(--RingRadius),
        transparent calc(var(--RingRadius) + 1px)
    );
    filter: blur(10px);
    transform: translateX(-40px);
`

const Background1 = styled.div`
    position: absolute;
    height: calc(100% + 80px);
    width: calc(100% + 80px);
    top: 0px;
    left: 0px;
    background-image: radial-gradient(
        circle at var(--RingX, 50%) calc(100% + var(--RingOffsetY)),
        transparent 0px,
        transparent calc(var(--RingRadius) - var(--RingWeight) - 1px),
        #0ea5ff calc(var(--RingRadius) - var(--RingWeight)),
        #0ea5ff var(--RingRadius),
        transparent calc(var(--RingRadius) + 1px)
    );
    filter: blur(25px);
    transform: translateX(-40px);
`

const Background2 = styled.div`
    position: absolute;
    height: calc(100% + 80px);
    width: calc(100% + 80px);
    top: 0px;
    left: 0px;
    background-image: radial-gradient(
        circle at var(--RingX, 50%) calc(100% + var(--RingOffsetY)),
        transparent 0px,
        transparent calc(var(--RingRadius) - var(--RingWeight2) - 1px),
        #3e4fec calc(var(--RingRadius) - var(--RingWeight2)),
        #3e4fec var(--RingRadius),
        transparent calc(var(--RingRadius) + 1px)
    );
    filter: blur(30px);
    transform: translateX(-40px);
`

const OperatorsImg = styled.img`
    height: auto;
    width: 88px;

    @media ${MqTablet} {
        width: auto;
    }
`

const StakesImg = styled.img`
    height: auto;
    width: 76px;

    @media ${MqTablet} {
        width: auto;
    }
`

const ApysImg = styled.img`
    height: auto;
    transform: translate(-10%, 5%);
    width: 122px;

    @media ${MqTablet} {
        transform: translate(15%, 0px);
        width: auto;
    }

    @media ${MqDesktop} {
        transform: translate(-10%, 5%);
    }
`

const StatDetails = styled.div`
    flex-grow: 1;

    ${({ $loading = false }) =>
        $loading &&
        css`
            h4 {
                animation: ${opacityPulseKeyframes} 1s infinite;
            }
        `}
`

const Stat = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    background: linear-gradient(rgba(0, 0, 0, 0.13) 0%, rgba(28, 59, 151, 0) 100%);
    backdrop-filter: blur(75px);
    padding: 48px;
    box-sizing: border-box;

    img {
        margin-top: 48px;
    }

    @media ${MqTablet} {
        flex-direction: row;
        height: auto;

        img {
            margin-top: 0;
        }
    }

    @media ${MqDesktop} {
        flex-direction: column;
        height: 480px;
    }
`

const Stats = styled.div`
    display: grid;
    gap: 28px;
    grid-template-columns: 1fr;
    margin: 0 auto;
    padding: 0 32px;
    box-sizing: border-box;
    max-width: 100%;
    width: 588px;

    h4 {
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 0.4em;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
    }

    img {
        display: block;
    }

    @media ${MqTablet} {
        h4 {
            font-size: 40px;
            line-height: 48px;
        }

        p {
            font-size: 22px;
            line-height: 32px;
        }
    }

    @media ${MqDesktop} {
        grid-template-columns: 1fr 1fr 1fr;
        width: 1224px;

        h4 {
            font-size: 48px;
            line-height: 56px;
        }
    }
`

const StakeAndEarnHeroRoot = styled(BaseHero)`
    --RingRadius: 430px;
    --RingWeight: 15px;
    --RingWeight2: 24px;
    --RingOffsetY: calc(var(--RingRadius) - 1100px);
    --RingX: calc(50% + 360px);

    background: #121622;
    background-image: radial-gradient(
            circle at 15% -15%,
            rgba(62, 79, 236, 0.25) 0,
            rgba(62, 79, 236, 0) 40%
        ),
        radial-gradient(circle at 90% -5%, rgba(62, 79, 236, 0.25) 0, rgba(62, 79, 236, 0) 25%),
        radial-gradient(circle at -15% 25%, rgba(62, 79, 236, 0.2) 0, rgba(62, 79, 236, 0) 25%);

    ${SkewedTile} {
        opacity: 0.7;
    }

    @media ${MqTablet} {
        --RingRadius: 1000px;
        --RingOffsetY: calc(var(--RingRadius) - 920px);
        --RingX: calc(50% + 790px);
    }

    @media ${MqDesktop} {
        --RingRadius: 1800px;
        --RingOffsetY: calc(var(--RingRadius) - 560px);
        --RingX: 50%;
    }
`
