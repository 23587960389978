import { Button } from '@streamr/streamr-layout'
import React from 'react'
import { MqDesktop } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import { Section, SectionTitle } from '../../Section'
import DiscordScreenshot from '../assets/discordScreenshot.png'
import DiscordScreenshot2x from '../assets/discordScreenshot@2x.png'

export function JoinTheOperatorCommunity() {
    return (
        <JoinTheOperatorCommunityRoot>
            <Content>
                <SectionTitle>Join the Operator community</SectionTitle>
                <Buttons>
                    <Button
                        tag="a"
                        href="https://discord.gg/gZAm8P7hK8"
                        rel="noopener noreferrer"
                        kind="primary"
                        size="big"
                    >
                        Explore Discord
                    </Button>
                </Buttons>
            </Content>
            <DiscordScreenshotWrap>
                <Inner>
                    <img
                        src={DiscordScreenshot}
                        srcSet={`${DiscordScreenshot2x} 2x`}
                        alt="Explore Discord"
                    />
                </Inner>
            </DiscordScreenshotWrap>
        </JoinTheOperatorCommunityRoot>
    )
}

const Inner = styled.div`
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(255, 255, 255, 0.4) 90%,
        rgba(255, 255, 255, 0.1) 90%
    );
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    padding: 20px;
    width: 540px;

    @media (min-width: 500px) {
        background: linear-gradient(180deg, rgba(27, 32, 48, 0.2) 0%, rgba(0, 0, 0, 0.048) 100%);
        padding: 32px;
        width: auto;
    }

    @media ${MqDesktop} {
        padding: 40px;
        border-radius: 20px;
    }
`

const DiscordScreenshotWrap = styled.div`
    margin: 60px auto 0;
    padding: 0 32px;
    max-width: 1060px;
    width: 100%;
    box-sizing: border-box;

    img {
        display: block;
        border-radius: 20px;
        width: 100%;
    }

    @media (min-width: 500px) {
        margin-top: 88px;
    }

    @media ${MqDesktop} {
        margin-top: 120px;
    }
`

const Buttons = styled.div``

const Content = styled.div`
    width: 824px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0 32px;
    box-sizing: border-box;
`

const JoinTheOperatorCommunityRoot = styled(Section)`
    background: #121622;
    color: #ffffff;

    && {
        padding-top: var(--defaultPadding);
    }
`
