import { Button } from '@streamr/streamr-layout'
import React from 'react'
import styled from 'styled-components'
import { StakeAndEarnQAs } from '../../../utils/faqs'
import { Section, SectionTitle } from '../../Section'

export function StakeAndEarnFaq() {
    return (
        <StakeAndEarnFaqRoot>
            <Content>
                <SectionTitle>Stake &amp; Earn FAQ</SectionTitle>
                <ul>
                    <StakeAndEarnQAs />
                </ul>
            </Content>
            <Buttons>
                <Button tag="a" href="https://streamr.network/faq" kind="primary" size="big">
                    More answers on FAQ
                </Button>
            </Buttons>
        </StakeAndEarnFaqRoot>
    )
}

const Buttons = styled.div`
    margin-top: 80px;
    text-align: center;
`

const Content = styled.div`
    margin: 0 auto;
    max-width: 704px;
    padding: 0 32px;
    box-sizing: border-box;

    > ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
    }

    ${SectionTitle} {
        text-align: center;
        margin: 0 0 80px;
    }
`

const StakeAndEarnFaqRoot = styled(Section)`
    color: #0c009a;

    && {
        padding-top: var(--defaultPadding);
    }
`
