import { StreamrIndexerUrl } from '../../../consts'
import { q } from '../../../utils/graphs'

/**
 * "Active" streams are the ones with non-zero messagesPerSecond. Excluded are
 * the coordination streams.
 *
 * @todo This same utility is implemented separately in the Hub repo (1), and the Metric
 * reporting tool (2). We may want to build another npm package with subgraph-related
 * utility and share them.
 * 1) https://github.com/streamr-dev/core-frontend
 * 2) https://github.com/streamr-dev/metrics-tracker
 */
export async function getActiveOperatorCount() {
    let cursor = '0'

    const uniquenessGate = {}

    let count = 0

    for (;;) {
        const streams = await q(StreamrIndexerUrl, {
            query: `
                query GetStreams($orderBy: StreamOrderBy, $orderDirection: OrderDirection, $cursor: String, $pageSize: Int) {
                    streams(orderBy: $orderBy, orderDirection: $orderDirection, cursor: $cursor, pageSize: $pageSize) {
                        cursor
                        items {
                            messagesPerSecond
                            id
                        }
                    }
                }
            `,
            variables: {
                cursor,
                orderBy: 'MESSAGES_PER_SECOND',
                orderDirection: 'DESC',
                pageSize: 500,
            },
            transform: (x) => {
                return x.data.streams
            },
        })

        const breakEarly = (() => {
            for (const item of streams.items) {
                if (!item.messagesPerSecond) {
                    /**
                     * Make the whole stream fetching skip streams that have no
                     * data flowing through them.
                     */
                    return true
                }

                if (!uniquenessGate[item.id]) {
                    if (/^[^/]+\/operator\/coordination$/.test(item.id)) {
                        count += 1
                    }
                }

                uniquenessGate[item.id] = true
            }

            return false
        })()

        if (!streams.cursor || breakEarly) {
            break
        }

        cursor = streams.cursor
    }

    return count
}
