import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'

export const Section = styled.div`
    --defaultPadding: 80px;

    max-width: 100%;
    overflow: hidden;
    padding: var(--defaultPadding) 0;

    & + & {
        padding-top: 56px;
    }

    @media ${MqTablet} {
        --defaultPadding: 120px;
    }

    @media ${MqDesktop} {
        --defaultPadding: 160px;
    }
`

export const SectionTitle = styled.h2`
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 var(--SectionTitleMarginBottom, 32px);
    max-width: 100%;

    @media ${MqTablet} {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: var(--SectionTitleMarginBottomTablet, 48px);
    }

    @media ${MqDesktop} {
        font-size: 60px;
        line-height: 66px;
        margin-bottom: var(
            --SectionTitleMarginBottomDesktop,
            var(--SectionTitleMarginBottomTablet, 48px)
        );
    }
`

export const SectionDescription = styled.p`
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    max-width: 100%;

    & + & {
        margin-top: 30px;
    }

    @media ${MqTablet} {
        font-size: 18px;
        line-height: 30px;
    }
`

export const SectionCta = styled.div`
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 6px 50px -6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: var(--SectionCta_Margin, 0 auto 0);
    padding: var(--SectionCta_Padding, 32px);
    width: var(--SectionCta_Width, 660px);
    max-width: 100%;
    box-sizing: border-box;

    a& {
        text-decoration: none;
    }

    ${({ $block = false }) =>
        !$block &&
        css`
            align-items: center;
            display: flex;
            gap: 10px;
        `}
`

export const SectionContent = styled.div`
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 32px;
    margin: 0 auto;
    width: 704px;

    @media ${MqTablet} {
        padding: 0 64px;
        width: 1288px;
    }
`
