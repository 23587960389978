import React from 'react'
import styled from 'styled-components'

export function TopBottomArrow() {
    return (
        <TopBottomArrowRoot width="7" height="88" xmlns="http://www.w3.org/2000/TopBottomArrowRoot">
            <circle cx="3.5" cy="3.5" r="2.5" fill="#F65F0A" />
            <line
                x1="3.5"
                x2="3.5"
                y1="3.5"
                y2="84.5"
                strokeDasharray="2 2"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line x1="3.5" y1="87.5" y2="84" x2="6.5" stroke="#F65F0A" strokeWidth="1" />
            <line x1="3.5" y1="87.5" y2="84" x2="0.5" stroke="#F65F0A" strokeWidth="1" />
        </TopBottomArrowRoot>
    )
}

const TopBottomArrowRoot = styled.svg`
    margin: 0 auto;
`
