import { useEffect, useState } from 'react'
import { getActiveOperatorCount } from '../components/StakeAndEarnPage/helpers/getActiveOperatorCount'
import { StreamrIndexerUrl } from '../consts'
import { getStakingStats, q } from './graphs'

export function formatFloat(value) {
    return value.toFixed(2).replace(/0+$/, '').replace(/\.$/, '')
}

export function formatInt(value) {
    return value
        .toFixed(0)
        .split('')
        .reverse()
        .reduce((memo, t) => `${t}${memo.length && memo.length % 3 === 0 ? ',' : ''}${memo}`, '')
}

export function useBasicNetworkStats() {
    const [operatorCount, setOperatorCount] = useState(undefined)

    const [stakeStats, setStakeStats] = useState()

    const totalStake = stakeStats?.tvl

    const apy = stakeStats?.apy

    useEffect(function fetchOperatorCount() {
        let mounted = true

        void (async () => {
            try {
                const count = await getActiveOperatorCount()

                if (mounted) {
                    setOperatorCount(count)
                }
            } catch (e) {
                console.warn('Failed to fetch the active operator count', e)
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    useEffect(function updateStakeStats() {
        let mounted = true

        void (async () => {
            try {
                const stats = await getStakingStats()

                if (mounted) {
                    setStakeStats(stats)
                }
            } catch (_) {
                // Noop.
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    return { operatorCount, totalStake, apy }
}

export function useStreamCount() {
    const [streamCount, setStreamCount] = useState(undefined)

    useEffect(function fetchStats() {
        let mounted = true

        void (async () => {
            try {
                const count = await q(StreamrIndexerUrl, {
                    query: `
                            query getStreamCount {
                                summary {
                                    streamCount
                                }
                            }
                        `,
                    transform: (x) => {
                        return Number(x.data.summary.streamCount)
                    },
                })

                if (mounted) {
                    setStreamCount(count)
                }
            } catch (e) {
                console.warn('Failed to fetch stream count', e)
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    return streamCount
}
