import { Button } from '@streamr/streamr-layout'
import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import { Section, SectionDescription, SectionTitle } from '../../Section'
import Delegator from '../assets/delegator.png'
import Delegator2x from '../assets/delegator@2x.png'
import NodeOperator from '../assets/nodeOperator.png'
import NodeOperator2x from '../assets/nodeOperator@2x.png'
import Sponsorship from '../assets/sponsorship.png'
import Sponsorship2x from '../assets/sponsorship@2x.png'
import { HorizontalCircleBack } from '../helpers/HorizontalCircleBack'
import { LeftRightArrow } from '../helpers/LeftRightArrow'
import { TopBottomArrow } from '../helpers/TopBottomArrow'
import { VerticalCircleBack } from '../helpers/VerticalCircleBack'

export function DelegateToAnOperator() {
    return (
        <DelegateToAnOperatorRoot>
            <Content>
                <SectionTitle>Delegate to an Operator</SectionTitle>
                <SectionDescription>
                    If you want to earn with your DATA tokens without the hassle of running a node,
                    consider becoming a&nbsp;Delegator.
                </SectionDescription>
                <SectionDescription>
                    Delegators are token holders who lend their DATA tokens to Node Operators
                    instead of managing nodes themselves. By doing this, they help Node Operators
                    increase their earnings. In return, the operators share a portion of their
                    profits with the delegators as&nbsp;a&nbsp;reward.
                </SectionDescription>
                <Buttons>
                    <Button
                        tag="a"
                        href="https://streamr.network/hub/network/operators"
                        kind="primary"
                        size="big"
                    >
                        Discover Operators
                    </Button>
                </Buttons>
            </Content>
            <VerticalFlowWrap>
                <FlowWrap>
                    <Flow>
                        <div />
                        <FlowTiles>
                            <FlowTile>
                                <h4>Delegator</h4>
                                <FlowTileImgWrap>
                                    <img
                                        src={Delegator}
                                        srcSet={`${Delegator2x} 2x`}
                                        alt="Delegator"
                                    />
                                </FlowTileImgWrap>
                            </FlowTile>
                            <div>
                                <TopBottomArrow />
                            </div>
                            <FlowTile>
                                <h4>Node Operator</h4>
                                <FlowTileImgWrap>
                                    <img
                                        src={NodeOperator}
                                        srcSet={`${NodeOperator2x} 2x`}
                                        alt="Node Operator"
                                    />
                                </FlowTileImgWrap>
                            </FlowTile>
                            <div>
                                <TopBottomArrow />
                            </div>
                            <FlowTile>
                                <h4>Sponsorship</h4>
                                <FlowTileImgWrap>
                                    <img
                                        src={Sponsorship}
                                        srcSet={`${Sponsorship2x} 2x`}
                                        alt="Sponsorship"
                                    />
                                </FlowTileImgWrap>
                            </FlowTile>
                        </FlowTiles>
                        <CircleBackWrap>
                            <VerticalCircleBack />
                        </CircleBackWrap>
                    </Flow>
                </FlowWrap>
            </VerticalFlowWrap>
            <HorizontalFlowView>
                <FlowWrap>
                    <Flow>
                        <FlowTiles>
                            <FlowTile>
                                <h4>Delegator</h4>
                                <FlowTileImgWrap>
                                    <img
                                        src={Delegator}
                                        srcSet={`${Delegator2x} 2x`}
                                        alt="Delegator"
                                    />
                                </FlowTileImgWrap>
                            </FlowTile>
                            <div>
                                <LeftRightArrow />
                            </div>
                            <FlowTile>
                                <h4>Node Operator</h4>
                                <FlowTileImgWrap>
                                    <img
                                        src={NodeOperator}
                                        srcSet={`${NodeOperator2x} 2x`}
                                        alt="Node Operator"
                                    />
                                </FlowTileImgWrap>
                            </FlowTile>
                            <div>
                                <LeftRightArrow />
                            </div>
                            <FlowTile>
                                <h4>Sponsorship</h4>
                                <FlowTileImgWrap>
                                    <img
                                        src={Sponsorship}
                                        srcSet={`${Sponsorship2x} 2x`}
                                        alt="Sponsorship"
                                    />
                                </FlowTileImgWrap>
                            </FlowTile>
                        </FlowTiles>
                        <HorizontalCircleBack />
                    </Flow>
                </FlowWrap>
            </HorizontalFlowView>
        </DelegateToAnOperatorRoot>
    )
}

const HorizontalFlowMq = '(min-width: 896px)'

const CircleBackWrap = styled.div`
    padding: 112px 0;
`

const Buttons = styled.div`
    margin-top: 56px;
`

const Content = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 32px;
    position: relative;
    text-align: center;
    width: 552px;
    z-index: 1;

    @media ${MqDesktop} {
        width: 824px;
    }
`

const FlowTileImgWrap = styled.div`
    height: 0;
    overflow: visible;
    position: relative;
    margin-top: 72px;
`

const FlowTile = styled.div`
    background: #ffffff;
    border-radius: 16px;
    height: 232px;
    box-shadow: 0px 6.30704px 50px -6.30704px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 28px;

    h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        margin: 0;
    }

    img {
        display: block;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media ${HorizontalFlowMq} {
        width: auto;
    }
`

const FlowTiles = styled.div`
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @media ${HorizontalFlowMq} {
        grid-template-columns: 206px 1fr 206px 1fr 206px;
        margin-bottom: 16px;
        width: auto;
    }
`

const Flow = styled.div`
    border-radius: 16px;
    box-sizing: border-box;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 192px 1fr;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 24px;
    width: 480px;

    svg {
        display: block;
    }

    @media ${MqTablet} {
        background: rgba(255, 255, 255, 0.3);
        padding: 64px 96px;
        grid-template-columns: 280px 1fr;
        width: 640px;
    }

    @media ${HorizontalFlowMq} {
        display: block;
        gap: 0px;
        grid-template-columns: none;
        padding: 52px;
        width: auto;
    }
`

const FlowWrap = styled.div`
    box-sizing: border-box;
    margin-top: 80px;

    @media ${MqTablet} {
        margin-top: 104px;
    }

    @media ${HorizontalFlowMq} {
        width: 100%;
        margin: 120px auto 0;
        max-width: 1224px;
        padding: 0 32px;
    }
`

const HorizontalFlowView = styled.div`
    display: none;

    @media ${HorizontalFlowMq} {
        display: block;
    }
`

const VerticalFlowWrap = styled.div`
    @media ${MqTablet} {
        ${Flow} > div:first-child {
            display: none;
        }
    }

    @media ${HorizontalFlowMq} {
        display: none;
    }
`

const DelegateToAnOperatorRoot = styled(Section)`
    background: #f5f5f5;
    background-image: radial-gradient(
            circle at 0% 100%,
            rgba(242, 238, 230, 1) 0px,
            rgba(242, 238, 230, 0) 1000px
        ),
        radial-gradient(circle at 80% 90%, rgba(242, 238, 230, 1) 0px, rgba(242, 238, 230, 0) 800px),
        linear-gradient(180deg, rgba(231, 230, 228, 0) 0%, #e7e6e4 100%);
    color: #0c009a;
`
