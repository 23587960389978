import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css, keyframes } from 'styled-components'
import { SkewedTile } from '../components/SkewedTile'

export const HeroHeading1 = css`
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
    text-align: center;
    padding: 0 32px;
    box-sizing: border-box;

    @media ${MqTablet} {
        font-size: 60px;
        line-height: 66px;
    }

    @media ${MqDesktop} {
        font-size: 88px;
        line-height: 98px;
    }
`

export const HeroHeading2 = css`
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 2em auto 0;
    max-width: 560px;
    text-align: center;
    padding: 0 32px;
    box-sizing: border-box;

    @media ${MqTablet} {
        font-size: 20px;
        line-height: 34px;
        width: 660px;
        max-width: 100%;
    }

    @media ${MqDesktop} {
        font-size: 22px;
        line-height: 36px;
        width: 724px;
    }
`

export const HeroSkewedTile = styled(SkewedTile)`
    --size: 220px;

    @media ${MqTablet} {
        --size: 284px;
    }

    @media ${MqDesktop} {
        --size: 320px;
    }
`

export const opacityPulseKeyframes = keyframes`
    0%, 100% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }
`
