import React from 'react'
import styled from 'styled-components'
import Layout from '../shared/Layout'
import Nav from '../shared/Nav'
import { BecomeANodeOperator } from './sections/BecomeANodeOperator'
import { DelegateToAnOperator } from './sections/DelegateToAnOperator'
import { JoinTheOperatorCommunity } from './sections/JoinTheOperatorCommunity'
import { StakeAndEarnHero } from './sections/StakeAndEarnHero'
import { StakeAndEarnFaq } from './sections/StakeAndEarnFaq'

export function StakeAndEarnPage() {
    return (
        <Layout nav={<Nav light />}>
            <StakeAndEarnPageRoot>
                <StakeAndEarnHero />
                <BecomeANodeOperator />
                <DelegateToAnOperator />
                <JoinTheOperatorCommunity />
                <StakeAndEarnFaq />
            </StakeAndEarnPageRoot>
        </Layout>
    )
}

const StakeAndEarnPageRoot = styled.div``
