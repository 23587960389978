import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import { HeroHeading1, HeroHeading2, HeroSkewedTile } from '../utils/css'
import { SkewedTileList } from './SkewedTile'

export function BaseHero({ className, head, icon, content, tail, tileOpacityRatio = 1 }) {
    return (
        <BaseHeroRoot className={className}>
            {head}
            <Content>
                <Elevator>{content}</Elevator>
                <Tiles>
                    <Elevator>{icon}</Elevator>
                    <HeroSkewedTile />
                    <HeroSkewedTile
                        style={{
                            '--ty': '-128%',
                            opacity: Math.min(1, tileOpacityRatio * 0.3),
                        }}
                    />
                    <HeroSkewedTile
                        style={{
                            '--tx': '-216%',
                            '--ty': '-128%',
                            opacity: Math.min(1, tileOpacityRatio * 0.15),
                        }}
                    />
                    <HeroSkewedTile
                        style={{
                            '--tx': '-216%',
                            '--ty': '-256%',
                            opacity: Math.min(1, tileOpacityRatio * 0.2),
                        }}
                    />
                    <HeroSkewedTile
                        style={{
                            '--tx': '-216%',
                            opacity: Math.min(1, tileOpacityRatio * 0.2),
                        }}
                    />
                    <HeroSkewedTile
                        style={{
                            '--tx': '108%',
                            '--ty': '-64%',
                            opacity: Math.min(1, tileOpacityRatio * 0.2),
                        }}
                    />
                    <HeroSkewedTile
                        style={{
                            '--tx': '-108%',
                            '--ty': '-64%',
                            opacity: Math.min(1, tileOpacityRatio * 0.25),
                        }}
                    />
                    <HeroSkewedTile
                        style={{
                            '--tx': '108%',
                            '--ty': '64%',
                            opacity: Math.min(1, tileOpacityRatio * 0.3),
                        }}
                    />
                    <HeroSkewedTile
                        style={{
                            '--tx': '-108%',
                            '--ty': '64%',
                            opacity: Math.min(1, tileOpacityRatio * 0.2),
                        }}
                    />
                </Tiles>
                {tail}
            </Content>
        </BaseHeroRoot>
    )
}

const Elevator = styled.div`
    position: relative;
    z-index: 1;
`

export const Tiles = styled(SkewedTileList)`
    --imgDy: -4%;
    --imgWidth: 240px;

    margin: 200px 0 180px;

    @media ${MqTablet} {
        --imgWidth: 300px;
        margin: 240px 0 280px;
    }

    @media ${MqDesktop} {
        --imgWidth: 350px;
        margin: 236px 0 440px;
    }
`

const Content = styled.div`
    color: var(--textColor);
    position: relative;

    h1 {
        ${HeroHeading1}
    }

    h1 em {
        color: #f65f0a;
        font-style: normal;
    }

    h2 {
        ${HeroHeading2}
    }
`

export const BaseHeroRoot = styled.div`
    --textColor: #ffffff;
    --Hero_Padding: 192px 0 120px;
    --Hero_Tablet_Padding: 216px 0 150px;
    --Hero_Desktop_Padding: 272px 0 200px;

    margin-top: -4.5rem;
    padding: var(--Hero_Padding);
    position: relative;
    overflow: hidden;

    @media ${MqTablet} {
        margin-top: -4rem;
        padding: var(--Hero_Tablet_Padding);
    }

    @media ${MqDesktop} {
        margin-top: -4.5rem;
        padding: var(--Hero_Desktop_Padding);
    }
`
