import React, { useEffect, useRef } from 'react'

export function VerticalCircleBack() {
    const svgRef = useRef(null)

    const line0Ref = useRef(null)

    const line1Ref = useRef(null)

    const line2Ref = useRef(null)

    const line3Ref = useRef(null)

    const line4Ref = useRef(null)

    const line5Ref = useRef(null)

    const circle0Ref = useRef(null)

    const circle1Ref = useRef(null)

    useEffect(function handleResize() {
        function onResize() {
            const { current: line0 } = line0Ref

            const { current: line1 } = line1Ref

            const { current: line2 } = line2Ref

            const { current: line3 } = line3Ref

            const { current: line4 } = line4Ref

            const { current: line5 } = line5Ref

            const { current: circle0 } = circle0Ref

            const { current: circle1 } = circle1Ref

            const { current: svg } = svgRef

            if (
                !svg ||
                !line0 ||
                !line1 ||
                !line2 ||
                !line3 ||
                !line4 ||
                !line5 ||
                !circle0 ||
                !circle1
            ) {
                return
            }

            const w = svg.clientWidth

            const h = svg.clientHeight

            circle0.setAttribute('cy', h - 3.5)

            circle1.setAttribute('cx', w - 3.5)

            line0.setAttribute('x2', w - 3.5)

            line1.setAttribute('x1', w - 3.5)

            line1.setAttribute('x2', w - 3.5)

            line1.setAttribute('y2', h - 3.5)

            line2.setAttribute('x1', w - 3.5)

            line2.setAttribute('y1', h - 3.5)

            line2.setAttribute('y2', h - 3.5)

            line3.setAttribute('x1', w - 3.5)

            line4.setAttribute('y2', h / 2 - 3)

            line5.setAttribute('y2', h / 2 + 3)
        }

        window.addEventListener('resize', onResize)

        onResize()

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
            <circle ref={circle0Ref} cx="3.5" cy="calc(100% - 3.5px)" r="2.5" fill="#F65F0A" />
            <circle ref={circle1Ref} cx="calc(100% - 3.5px)" cy="50%" r="2.5" fill="#F65F0A" />
            <line
                ref={line0Ref}
                x1="3.5"
                y1="3.5"
                x2="calc(100% - 3.5px)"
                y2="3.5"
                strokeDasharray="2 2"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line
                ref={line1Ref}
                x1="calc(100% - 3.5px)"
                y1="3.5"
                x2="calc(100% - 3.5px)"
                y2="calc(100% - 3.5px)"
                strokeDasharray="2 2"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line
                ref={line2Ref}
                x1="calc(100% - 3.5px)"
                y1="calc(100% - 3.5px)"
                x2="0.5"
                y2="calc(100% - 3.5px)"
                strokeDasharray="2 2"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line
                ref={line3Ref}
                x1="calc(100% - 3.5px)"
                y1="50%"
                x2="0.5"
                y2="50%"
                strokeDasharray="2 2"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line
                ref={line4Ref}
                x1="0.5"
                y1="50%"
                x2="3.5"
                y2="calc(50% - 3px)"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line
                ref={line5Ref}
                x1="0.5"
                y1="50%"
                x2="3.5"
                y2="calc(50% + 3px)"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line x1="0.5" y1="3.5" x2="4" y2="0.5" stroke="#F65F0A" strokeWidth="1" />
            <line x1="0.5" y1="3.5" x2="4" y2="6.5" stroke="#F65F0A" strokeWidth="1" />
        </svg>
    )
}
