import React, { useEffect, useRef } from 'react'

export function LeftRightArrow() {
    const svgRef = useRef(null)

    const line0Ref = useRef(null)

    const line1Ref = useRef(null)

    const line2Ref = useRef(null)

    useEffect(function handleResize() {
        function onResize() {
            const { current: line0 } = line0Ref

            const { current: line1 } = line1Ref

            const { current: line2 } = line2Ref

            const { current: svg } = svgRef

            if (!line0 || !svg || !line1 || !line2) {
                return
            }

            line0.setAttribute('x2', svg.clientWidth - 3.5)

            line1.setAttribute('x1', svg.clientWidth - 3.5)

            line1.setAttribute('x2', svg.clientWidth - 0.5)

            line2.setAttribute('x1', svg.clientWidth - 3.5)

            line2.setAttribute('x2', svg.clientWidth - 0.5)
        }

        window.addEventListener('resize', onResize)

        onResize()

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <svg width="100%" height="7" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
            <circle cx="3.5" cy="3.5" r="2.5" fill="#F65F0A" />
            <line
                ref={line0Ref}
                x1="3.5"
                x2="calc(100% - 3.5px)"
                y1="3.5"
                y2="3.5"
                strokeDasharray="2 2"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line
                ref={line1Ref}
                x1="calc(100% - 3.5px)"
                y1="0.5"
                y2="3.5"
                x2="calc(100% - 0.5px)"
                stroke="#F65F0A"
                strokeWidth="1"
            />
            <line
                ref={line2Ref}
                x1="calc(100% - 3.5px)"
                y1="6.5"
                y2="3.5"
                x2="calc(100% - 0.5px)"
                stroke="#F65F0A"
                strokeWidth="1"
            />
        </svg>
    )
}
