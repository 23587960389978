import styled from 'styled-components'

export const SkewedTile = styled.div`
    height: var(--size, 320px);
    left: 50%;
    margin: 0 auto;
    overflow: visible;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) translate(var(--tx, 0px), var(--ty, 0px)) rotateX(54deg);
    width: var(--size, 320px);

    &::before {
        background: var(--bg, rgba(255, 255, 255, 0.05));
        border-radius: 12px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: rotateZ(45deg);
        width: 100%;
    }
`

export const SkewedTileList = styled.div`
    height: 1px;
    position: relative;

    img {
        height: var(--imgHeight, auto);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) translate(var(--imgDx, 0px), var(--imgDy, 0px));
        width: var(--imgWidth, auto);
    }
`
